import {
  Assignment,
  Close,
  Email,
  Lock,
  Person,
  Today,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Slide,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Editor from "@draft-js-plugins/editor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import Question from "../Question";
import { useDebounce } from "../useDebounce";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { StatusPicker } from "./StatusPicker";
import { Status } from "../types";
import { DateTime } from "luxon";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";

const inlineToolbarPlugin = createInlineToolbarPlugin();
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions } = emojiPlugin;
const { InlineToolbar } = inlineToolbarPlugin;

interface Props {
  question: Question | null;
  isOpen: boolean;
  onClose: () => void;
}

const modalStyle = (fullScreen: boolean) =>
  ({
    position: "absolute" as const,
    margin: "auto",
    width: fullScreen ? "100%" : 800,
    height: fullScreen ? "100%" : "auto",
    maxHeight: fullScreen ? "100%" : "calc(100% - 64px)",
    maxWidth: fullScreen ? "100%" : "calc(100% - 64px)",
    bgcolor: "background.paper",
    borderRadius: fullScreen ? 0 : 4,
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    overflowX: "hidden",
  } as SxProps);

export function QuestionModal({ question, isOpen, onClose }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editorState, setEditorState] = useState(
    question?.notes
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(question.notes))
        )
      : EditorState.createEmpty()
  );
  const debouncedEditorState = useDebounce(editorState, 500);

  const lastQuestion = useRef<Question | null>(null);

  if (question !== lastQuestion.current && question) {
    lastQuestion.current = question;
  }

  const q = lastQuestion.current;

  async function updateStatus(status: Status) {
    if (!q) return;

    const current = doc(db, "questions", q.id);
    await setDoc(current, { status }, { merge: true });
    q.status = status;
  }

  useEffect(() => {
    if (q) {
      setEditorState(
        q.notes
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(q.notes)))
          : EditorState.createEmpty()
      );
    }
  }, [q]);

  useEffect(() => {
    if (q) {
      q.notes = JSON.stringify(
        convertToRaw(debouncedEditorState.getCurrentContent())
      );

      const current = doc(db, "questions", q.id);
      setDoc(
        current,
        {
          notes: q.notes,
        },
        { merge: true }
      );
    }
  }, [debouncedEditorState, q]);

  if (!q) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Slide in={isOpen} direction="up">
        <Box sx={modalStyle(fullScreen)}>
          <Box paddingBottom={4}>
            <Stack direction="row">
              <Box flexGrow={1}>
                <Typography variant="overline">Question:</Typography>
                <Typography variant="h4">{q.question}</Typography>
              </Box>
              <Box>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          <Box paddingBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Assignment />
                  <StatusPicker status={q.status} onChange={updateStatus} />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                {q.createdAt && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Today />
                    <Typography variant="body1">
                      {DateTime.fromJSDate(q.createdAt.toDate()).toFormat(
                        "DDD"
                      )}
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box paddingBottom={4}>
            <Typography variant="overline">Contact:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {q.anonymous ? <Lock /> : <Person />}
                  <Typography variant="body1">
                    {q.anonymous ? `(anonymous) ${q.name}` : q.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Email />
                  <Typography variant="body1">
                    <a href={`mailto:${q.email}`}>{q.email}</a>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="overline">Notes:</Typography>
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              placeholder="Enter notes here..."
              plugins={[inlineToolbarPlugin, emojiPlugin]}
            />
            <EmojiSuggestions />
            <InlineToolbar />
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}
