import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const app = initializeApp({
  apiKey: "AIzaSyAxvA9J9xJ1HRJYa7jkiPD8iVqy5pSZFdE",
  authDomain: "dn-ask-nebraska-demo.firebaseapp.com",
  projectId: "dn-ask-nebraska-demo",
  storageBucket: "dn-ask-nebraska-demo.appspot.com",
  messagingSenderId: "706341145791",
  appId: "1:706341145791:web:2e7ce444e534f369d8ca0a",
});

export const auth = getAuth(app);
auth.useDeviceLanguage();
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
