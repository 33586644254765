import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth, db } from "../firebase";
import useCollection from "../useCollection";
import Question from "../Question";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellEditCommitParams,
  GridColDef,
  GridInputSelectionModel,
  GridRenderEditCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { css } from "@emotion/css";
import { Delete, Launch } from "@mui/icons-material";
import { QuestionModal } from "../components/QuestionModal";

async function updateStatus({ id, value }: GridCellEditCommitParams) {
  const current = doc(db, "questions", id as string);
  return setDoc(current, { status: value }, { merge: true });
}

async function deleteQuestion(id: string) {
  await deleteDoc(doc(db, "questions", id));
}

function StatusInputCell(props: GridRenderEditCellParams) {
  const { id, value, api, field } = props;
  const [open, setOpen] = useState(true);

  async function handleChange(event: SelectChangeEvent<string>) {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    if (event instanceof PointerEvent) {
      if (event.clientX !== 0 && event.clientY !== 0) {
        await api.commitCellChange({ id, field });
        api.setCellMode(id, field, "view");
      }
    }
  }

  const select = css`
    width: 100%;
    height: 100%;
    & fieldset {
      border: none;
    }
  `;

  return (
    <Box style={{ flex: 1 }}>
      <Select
        className={select}
        value={value as string}
        onChange={handleChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <MenuItem value="new">New</MenuItem>
        <MenuItem value="investigating">Investigating</MenuItem>
        <MenuItem value="answered">Answered</MenuItem>
        <MenuItem value="closed">Closed</MenuItem>
      </Select>
    </Box>
  );
}

function renderStatusEdit(params: GridRenderEditCellParams) {
  return <StatusInputCell {...params} />;
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const { data, update } = useCollection<Question>(db, "questions");
  const hideDashboardText = useMediaQuery("(max-width: 800px)");
  const hideNameText = useMediaQuery("(max-width: 420px)");
  const [questionDetail, setQuestionDetail] = useState<Question | null>(null);
  const [selection, setSelection] = useState([] as GridInputSelectionModel);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "View",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<Launch />}
            label="View Question"
            onClick={() => setQuestionDetail(params.row as Question)}
          />,
        ],
        width: 80,
      } as any,
      { field: "question", headerName: "Question", width: 400 },
      {
        field: "status",
        type: "singleSelect",
        headerName: "Status",
        editable: true,
        renderEditCell: renderStatusEdit,
        width: 200,
        valueOptions: ["new", "investigating", "answered", "closed"],
        valueFormatter: ({ value }) =>
          (value as string)?.charAt(0).toUpperCase() +
          (value as string)?.slice(1),
      },
      {
        field: "name",
        headerName: "Contact Name",
        width: 200,
      },
      {
        field: "email",
        headerName: "Contact Email",
        width: 300,
        renderCell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
      },
      {
        field: "anonymous",
        headerName: "Anonymous",
        type: "boolean",
        width: 120,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        type: "date",
        width: 200,
        valueGetter: (data) => data.row.createdAt?.toDate() ?? new Date(),
      },
    ],
    []
  );

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (!user || !user.email?.endsWith("@dailynebraskan.com")) {
        signOut(auth);
        navigate("/#invalid");
      } else {
        setUser(user);
      }
    });
  }, [navigate]);

  async function deleteSelection() {
    if (!(selection instanceof Array)) return;

    await Promise.all(selection.map((id) => deleteQuestion(id as string)));

    update();
  }

  async function handleLogout() {
    await signOut(auth);
    navigate("/");
  }

  if (!user) {
    return null;
  }

  return (
    <Box
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          {selection instanceof Array && selection.length > 0 && (
            <IconButton
              aria-label="delete"
              color="inherit"
              style={{ marginRight: 12, paddingLeft: 0 }}
              onClick={() => deleteSelection()}
            >
              <Delete />
            </IconButton>
          )}
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {selection instanceof Array && selection.length > 0 ? (
              <span>Delete Selection</span>
            ) : (
              <span>
                Curious&nbsp;Cornhuskers&nbsp;
                <span hidden={hideDashboardText}>Dashboard</span>
              </span>
            )}
          </Typography>
          <Typography hidden={hideNameText} variant="body2">
            {user.displayName}
          </Typography>
          <IconButton
            aria-label="log out"
            color="inherit"
            onClick={handleLogout}
            style={{ marginLeft: 12 }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          style={{ border: "none" }}
          checkboxSelection
          columns={columns}
          rows={data}
          onCellEditCommit={updateStatus}
          selectionModel={selection}
          onSelectionModelChange={(selectionModel) => {
            setSelection(selectionModel);
          }}
        />
      </Box>

      <QuestionModal
        isOpen={!!questionDetail}
        onClose={() => setQuestionDetail(null)}
        question={questionDetail}
      />
    </Box>
  );
}
