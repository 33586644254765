import { css } from "@emotion/css";
import { Alert, Snackbar } from "@mui/material";
import { onAuthStateChanged, signInWithRedirect, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLoginButton } from "../components/GoogleLoginButton";
import { auth, provider } from "../firebase";

const center = css`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background: #f5f1e7;
`;

function Login() {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const navigate = useNavigate();

  async function handleLogin() {
    signInWithRedirect(auth, provider);
  }

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        navigate("/dashboard");
      }
    });
  }, [navigate]);

  return (
    <div className={center}>
      {user === null ? (
        <GoogleLoginButton onClick={handleLogin} />
      ) : (
        <div>Loading...</div>
      )}
      <Snackbar
        open
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="warning">
          Please sign in with a Daily Nebraskan Google Workspace account.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
