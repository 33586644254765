import { css } from "@emotion/css";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import produce, { immerable } from "immer";
import { ChangeEvent, FormEventHandler, useState } from "react";
import { getToken, init as initReCaptcha } from "../captcha";

const FUNCTION_URL =
  "https://us-central1-dn-ask-nebraska-demo.cloudfunctions.net/addQuestion";

const wrapper = css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f5f1e7;
  overflow-x: hidden;
  padding: 20px 0;

  @media (max-width: 600px) {
    display: block;
    padding: 0;
  }
`;

const container = css`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  background: white;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: auto;

  @media (max-width: 600px) {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
  }
`;

const group = css`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const personalInfoField = css`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 20px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
  }
`;

const cover = (color: string, success: boolean) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${color};
  border-radius: 10px;
  z-index: 10;
  display: grid;
  place-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  & .icon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0) scale(1);
  }

  ${
    !success &&
    css`
    opacity: 0;
    pointer-events: none;

    & .icon {
      transform: rotate(2deg) scale(0);
    }
  `
  }
`;

const reCaptchaDisclaimer = css`
  color: #9e9e9e;
  font-size: 12px;
  margin: 16px 8px 4px 8px;

  & a {
    color: #4c5fad;
    text-decoration: underline;
  }
`;

class QuestionData {
  public question = "";
  public email = "";
  public name = "";

  public newsletter = false;
  public anonymous = false;
  public age = false;
  public tos = false;

  [immerable] = true;
}

export default function Submit() {
  const theme = useTheme();
  const [questionData, setQuestionData] = useState<QuestionData>(
    new QuestionData()
  );

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | false>(false);

  initReCaptcha();

  function handler(field: keyof QuestionData) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setError("");

      const value = {
        checkbox: event.target.checked,
        text: event.target.value,
        textarea: event.target.value,
        email: event.target.value,
      }[event.target.type];

      setQuestionData(
        produce(
          questionData,
          (draft: Record<keyof QuestionData, string | boolean>) => {
            draft[field] = value!;
          }
        )
      );
    };
  }

  async function handleSubmit() {
    setLoading(true);
    setError("");

    const recaptchaToken = await getToken("submit");

    const result = await fetch(FUNCTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...questionData, token: recaptchaToken }),
    }).catch((error) => ({
      ok: false,
      json: () => ({ message: error.message }),
    }));

    if (result.ok) {
      setSuccess(true);
      setError(false);

      setTimeout(() => setSuccess(false), 2000);
      setTimeout(() => setQuestionData(new QuestionData()), 1000);
    } else {
      const { message } = await result.json();
      setSuccess(false);
      setError(message);
    }
    setLoading(false);
  }

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={cover(theme.palette.success.main, success)}>
          <DoneIcon
            className="icon"
            style={{
              color: theme.palette.success.contrastText,
              fontSize: "6em",
            }}
          />
        </div>
        <img src="/assets/header-bubble.svg" alt="Curious Cornhuskers" />
        <form
          action=""
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: "0 20px 20px 20px",
          }}
          onSubmit={handleFormSubmit}
        >
          <FormControl
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            variant="filled"
          >
            <TextField
              label="Question"
              id="question"
              multiline
              minRows={3}
              required
              value={questionData.question}
              onChange={handler("question")}
              placeholder="What do you want to know about UNL, its culture, or its people?"
            />
            <FormGroup className={group}>
              <div>
                <Typography variant="overline">Contact Information</Typography>
                <Typography variant="body2">
                  We may be in touch when we look into your question.
                </Typography>
              </div>
              <div className={personalInfoField}>
                <TextField
                  label="Name"
                  id="name"
                  required
                  value={questionData.name}
                  onChange={handler("name")}
                />
                <TextField
                  label="Email address"
                  id="email"
                  required
                  value={questionData.email}
                  onChange={handler("email")}
                  type="email"
                  error={error === "Please provide a valid email."}
                />
              </div>
            </FormGroup>
            <FormGroup className={group}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={questionData.newsletter}
                    onChange={handler("newsletter")}
                  />
                }
                label="Sign me up for your newsletter!"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={questionData.anonymous}
                    onChange={handler("anonymous")}
                  />
                }
                label="Please don't publish my name"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={questionData.age}
                    onChange={handler("age")}
                  />
                }
                label="I am over 16 years old"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={questionData.tos}
                    onChange={handler("tos")}
                  />
                }
                label={
                  <>
                    I agree to the <a href="/terms.html">Terms of Service</a>
                  </>
                }
              />
            </FormGroup>
            <div style={{ flexGrow: 1 }} />
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Submit"}
            </Button>
            <p className={reCaptchaDisclaimer}>
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>
              {" and "}
              <a href="https://policies.google.com/terms">Terms of Service</a>
              {" apply."}
            </p>
          </FormControl>
        </form>
        <Snackbar
          open={!!error}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}
