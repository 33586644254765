import { ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#d00000",
    },
    secondary: {
      main: "#249ab5",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
};

export const theme = createTheme(themeOptions);
