const RECAPTCHA_SITE_KEY = "6Lf0X7UdAAAAANJLInNvuLdVGnYCZ2dLVio-u-uo";

declare const grecaptcha: any;

export function init() {
  let el = document.getElementById("captcha");
  if (!el) {
    el = document.createElement("script");
    el.id = "captcha";
    (el as any).src =
      "https://www.google.com/recaptcha/api.js?render=" + RECAPTCHA_SITE_KEY;
    document.body.appendChild(el);
  }
}

export function getToken(action: string): Promise<string> {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(async () => {
      grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action })
        .then((token: string) => {
          resolve(token);
        })
        .catch((err: any) => {
          console.error(err);
          reject(err);
        });
    });
  });
}
