import { css } from "@emotion/css";
import googleLogo from "./GoogleLoginAssets/normal";

const btn = css`
  height: 40px;
  border: 0;
  outline: 0;
  background: white;
  padding: 0 12px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  column-gap: 14px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  transform-origin: center;
  transform: scale(1.2);
  cursor: pointer;
  border-radius: 2px;

  &:active {
    background: rgb(238, 238, 238);
  }
`;

interface Props {
  onClick: () => void;
}

export function GoogleLoginButton({ onClick }: Props) {
  return (
    <button className={btn} onClick={onClick}>
      {googleLogo} Sign in with Google
    </button>
  );
}
