import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Submit from "./pages/Submit";
import { theme } from "./theme";

function App() {
  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/submit" element={<Submit />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
