import { Chip, Menu, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Status } from "../types";

interface Props {
  status: Status;
  onChange: (value: Status) => void;
}

const options: Status[] = ["new", "investigating", "answered", "closed"];

const colorMap = new Map([
  ["new", "secondary"],
  ["investigating", "warning"],
  ["answered", "success"],
  ["closed", "error"],
] as const);

export function StatusPicker({ onChange, status: baseStatus }: Props) {
  const anchorElement = useRef(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(baseStatus);

  useEffect(() => {
    if (baseStatus !== status) {
      setStatus(baseStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseStatus]);

  function handleStatusChange(status: Status) {
    onChange(status);
    setOpen(false);
    setStatus(status);
  }

  return (
    <>
      <Chip
        label={status[0].toLocaleUpperCase() + status.slice(1)}
        ref={anchorElement}
        clickable
        onClick={() => setOpen(true)}
        color={colorMap.get(status) ?? "default"}
        // icon={<Assignment />}
      />
      <Menu
        open={open}
        anchorEl={anchorElement.current}
        onClose={() => setOpen(false)}
      >
        {options.map((opt) => (
          <MenuItem key={opt} onClick={() => handleStatusChange(opt)}>
            {opt}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
