import { Firestore } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { collection, getDocs } from "firebase/firestore";

export default function useCollection<T>(
  db: Firestore,
  collectionName: string
) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);

  const c = useMemo(() => collection(db, collectionName), [db, collectionName]);

  async function update() {
    setLoading(true);
    const { docs } = await getDocs(c);
    const data = docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setData(data as unknown as T[]);
    setLoading(false);
  }

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [c]);

  return { data, loading, update };
}
